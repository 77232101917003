// src/components/ProductCard.tsx
import React, { useState, useEffect } from 'react'; //Suspense, lazy
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Product, displayPrice } from '../utils/types'; // Import the types
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { IconContext } from "react-icons";
import { FaShieldHeart, FaLink } from "react-icons/fa6";
// import { Link } from 'react-router-dom'; // Import Link for navigation

// import { useNavigate } from 'react-router-dom';
import ProductModal from './ProductModal'; // Import your modal component
// import { Height } from '@mui/icons-material';

// import { hasRole, isAuthenticated } from '../utils/auth';
// import GetMTPrice from './GetMTPrice'; // Import the new component

// const LazyGetMTPriceS = lazy(() => import('./GetMTPriceS'));

interface ProductCardProps {
    product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
    // const [showModal, setShowModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    // const navigate = useNavigate();

    // Set threshold to 0.05 for 5% visibility
    const { ref, inView } = useInView({
        // Load the image when it's near the viewport, e.g., 300px away
        rootMargin: '300px',
        threshold: 0.1, // Load at 10% visibility
    });

    const [hasImageLoaded, setHasImageLoaded] = useState(false); // Track if image has loaded

    // Check if the image is in view and load it only if it hasn't been loaded yet
    useEffect(() => {
        if (inView && !hasImageLoaded) {
            setHasImageLoaded(true);
        }
    }, [inView, hasImageLoaded]);

    // Animation for flipping effect

    const props = useSpring({
        transform: inView ? 'scale(1)' : 'scale(0.5)',
        opacity: inView ? 1 : 0.5, // Fade in/out for a smoother effect
        config: { tension: 100, friction: 20 },
    });


    // Utility function to truncate the product name
    const truncateProductName = (name: string, maxLength: number) => {
        return name.length > maxLength ? name.slice(0, maxLength) + '...' : name;
    };
    const truncateProductDesc = (name: string, maxLength: number) => {
        return name.length > maxLength ? name.slice(0, maxLength) + '...' : name;
    };

    const handleModalOpen = () => {
        setShowModal(true);
        // navigate(`/product/${product.id}`, { state: { from: 'home' } });
    };

    const handleClose = () => {
        setShowModal(false);
        // navigate(-1); // Go back to the previous page
    };

    return (
        // <div className="flex flex-col items-center	 w-1/2 md:w-1/4 border rounded p-4" onClick={() => setShowModal(true)} ref={ref}>
        <div className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 border border-b-2 border-b-gray-400 rounded px-1 py-1 mx-0 my-1">

            {/* <div className="w-1/2 md:w-1/5 border border-gray-100 rounded px-1 py-1 mx-0 my-1 " ref={ref}>
            {/* <Link key={product.id} to={`/product/${product.id}`} state={{ from: 'home' }}> */}

            <div className="w-full h-48 md:w-full md:h-48 rounded p-1 flex flex-col justify-center items-center" ref={ref} onClick={handleModalOpen}>
                <animated.div style={props}>
                    {/* Load image only once */}
                    {hasImageLoaded && (
                        <img
                            src={product.media.length > 0 ? product.media[0].preview_url : 'placeholder.jpg'}
                            alt={product.product_name}
                            className="product-image px-1 py-1 h-40 md:h-46 object-cover"
                            loading="lazy"
                        />
                    )}
                </animated.div>
            </div>
            <div className="min-h-16 md:min-h-16 rounded px-2 py-0" >
                <p className="text-xs text-gray-500 font-thin" >{product.cat_name}</p>

                {/* Tooltip wraps the truncated name */}
                <h3
                    className="text-sm tracking-[0.08em]	text-black font-bold subpixel-antialiased"
                    style={{ fontSize: '0.9rem', }}
                    data-tooltip-id={`${product.id}`}
                    data-tooltip-content={product.product_name}
                >
                    {product.product_name.length > 68
                        ? truncateProductName(product.product_name, 68)
                        : product.product_name}

                    {/* Render the tooltip component outside the conditional */}
                    {product.product_name.length > 68 && (
                        <ReactTooltip
                            id={`${product.id}`}
                            place="bottom"
                            style={{ backgroundColor: "yellow", color: "#000000", fontSize: '1rem' }}
                        />
                    )}
                </h3>
            </div>

            <div className="min-h-20 rounded  px-2 py-2" >
                {/* Tooltip wraps the truncated name */}
                <p className="text-sm tracking-[0.01em] text-gray-700  font-normal break-all"
                    style={{ fontSize: '0.8rem', }}
                    data-tooltip-id={`${product.id}desc`}
                    data-tooltip-content={product.product_desc}>
                    {/* {truncateProductDesc(product.product_desc, 78)} */}
                    {product.product_desc !== null && product.product_desc.length > 95
                        ? truncateProductDesc(product.product_desc, 95)
                        : product.product_desc
                    }
                </p>
                {product.product_desc !== null && product.product_desc.length > 95 && (<ReactTooltip
                    id={`${product.id}desc`}
                    place="bottom"
                    style={{ backgroundColor: "green", color: "#FFFFFF", fontSize: '1em' }}

                />
                )}

            </div>
            <div className="min-h-6 rounded px-2 py-0" ref={ref} onClick={handleModalOpen}>
                <p className="mt-0 text-blue-600 font-normal break-all" style={{ fontSize: '0.8em' }}>
                    SKU: {product.product_code}
                </p>
            </div>

            <div className=" text-right rounded px-2 py-0 ">

                {product?.MTPriceArray && product.MTPriceArray.length > 0 && (
                    <div>
                        {/* <h3>MT Prices:</h3> */}
                        <ul>
                            {product.MTPriceArray.map((item, index) => (
                                <li key={index}>
                                    <span className='my-1 py-0 text-xs text-green-600 font-semibold' style={{ fontSize: '0.8em' }}>{item.cat_name}: {displayPrice(item.price)}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}


                {product?.MTPrice !== undefined && product?.MTPrice > 0 && (
                    <p className="my-1 py-0 text-xs text-green-600 font-semibold">VIP  <span className='font-bold text-lg'>RM {displayPrice(product.MTPrice)}</span></p>
                )}
                <p className="my-1 py-0 text-xs text-black">SRP <span className='font-bold text-lg'>RM {displayPrice(product.product_value)}</span></p>
            </div>

            {/* Product Modal */}
            {showModal && (
                <ProductModal product={product} onClose={handleClose} />
            )}

            {/* </Link> */}


            <div className="flex flex-row justify-between mt-2 ">
                {/* Stock status */}
                {product?.stock !== undefined && product?.stock > 0 ? (
                    <span className="productCardStock px-2 py-1 my-1 text-white bg-green-600 border rounded-lg shadow ">In Stock</span>
                ) : (
                    // <span className="productCardStock px-2 py-1 my-1 text-white bg-red-600 border rounded-lg shadow">Out of Stock</span>
                    <span className="productCardStock px-2 py-1 my-1 text-white bg-orange-600 border rounded-lg shadow">Back Order</span>
                )}

                {/* Restock information */}
                {product?.restock !== undefined && product?.restock !== 0 && product?.restock !== null && (
                    <span className="productCardStock px-2 py-1 my-1 text-white bg-purple-600 border rounded-lg shadow" >
                        {/* Re-Stock: {product.restock} */}
                        Re-Stock
                        {product?.restock_at !== undefined && product?.restock_at !== null && (
                            <> on {new Date(product.restock_at).toLocaleDateString()}</> // Formats the restock date
                        )}
                    </span>
                )}

                <div className='flex flex-row justify-between mt-2'>

                    {/* Conditional rendering for product link icon */}
                    {product.product_link && (
                        <a
                            href={product.product_link}
                            className='mr-2'
                            target="_blank"
                            rel="noreferrer"
                            data-tooltip-id={`link${product.id}`}
                            data-tooltip-content={`External ` + product.product_name + ` Product Details Link`}
                        >
                            <IconContext.Provider value={{ size: "1em", color: "purple", className: "" }}>
                                <div>
                                    <FaLink />
                                </div>
                            </IconContext.Provider>

                            {/* <ReactTooltip
                            id={`link${product.id}`}
                            place="right"
                            style={{ backgroundColor: "yellow", color: "#000000" }}
                        /> */}
                        </a>
                    )}

                    {/* Conditional rendering for warranty icon */}
                    {product.warranty && (
                        <IconContext.Provider value={{ size: "1em", color: "brown", className: "" }}>
                            <div
                                data-tooltip-id={`warranty${product.id}`}
                                data-tooltip-content={`${product.warranty} warranty`}
                            >
                                <FaShieldHeart />
                            </div>
                        </IconContext.Provider>
                    )}

                    {/* Tooltip for warranty icon */}
                    {product.warranty && (
                        <ReactTooltip
                            id={`warranty${product.id}`}
                            place="top"
                            style={{ backgroundColor: "yellow", color: "#000000" }}
                        />
                    )}
                </div>
            </div>


        </div>
    );
};

export default ProductCard;