// src/pages/Logout.tsx

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeAuthToken } from '../utils/auth';

const Logout: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Clear user data from local storage
        // localStorage.removeItem('token');
        removeAuthToken();

        localStorage.removeItem('user');
        localStorage.removeItem('rolesPermissions');

        // Redirect to login or home page
        navigate('/login'); // Change this to your desired route

    }, [navigate]);

    return (
        <div>
            <p>Logging out...</p>
        </div>
    );
};

export default Logout;