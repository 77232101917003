// src / components / ProductDetails.tsx
import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { Product } from '../utils/types';

interface ProductDetailsProps {
    product: Product;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({ product }) => {
    const sanitizedHtml = product.details;

    // Configure DOMPurify to allow iframes only for specific YouTube domains
    const cleanHtml = DOMPurify.sanitize(sanitizedHtml, {
        ADD_TAGS: ['iframe', 'img', 'embed', 'a'],
        ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'src', 'width', 'height', 'alt', 'type', 'href', 'target', 'rel'],
        // Allow unrestricted URIs for images, PDFs, and links but restrict iframe sources to YouTube domains
        FORBID_TAGS: [''],
        ALLOWED_URI_REGEXP: /^(https?:\/\/(www\.)?(youtube\.com|youtube-nocookie\.com)\/embed\/|https?:\/\/.*\.(jpg|jpeg|png|webp|avif|gif|svg|pdf)|http?:\/\/.*\.(jpg|jpeg|png|webp|avif|gif|svg|pdf)|https?:\/\/.*|http?:\/\/.*)/
    });


    // Function to add rel="noopener noreferrer" to all links with target="_blank"
    const addNoopenerNoreferrer = (html: string) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        const links = div.querySelectorAll('a');
        links.forEach(link => {
            link.setAttribute('target', '_blank');
            link.setAttribute('rel', 'noopener noreferrer');
        });
        return div.innerHTML;
    };

    const finalHtml = addNoopenerNoreferrer(cleanHtml);

    if (!sanitizedHtml) return null;

    return (
        <div
            className='inDetails pb-5 mb-36 text-md font-normal break-all'
            // dangerouslySetInnerHTML={{ __html: cleanHtml }}
            dangerouslySetInnerHTML={{ __html: finalHtml }}
        />
    );
};

export default ProductDetails;
