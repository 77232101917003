import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { hasRole, isAuthenticated } from '../utils/auth';


const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // const token = localStorage.getItem('token');
    let user = null;
    // console.log(localStorage.getItem('user'));
    if (localStorage.getItem('user') !== 'undefined') {
        user = JSON.parse(localStorage.getItem('user') || 'null');
    } else {
        user = null;
    }
    // const user = JSON.parse(localStorage.getItem('user') || 'null');

    // console.log(user, token);

    return (
        <header className="sticky top-0 z-30 flex items-center justify-between bg-blue-800 text-white px-4 py-1">
            <h1 className="text-lg md:text-2xl">{process.env.REACT_APP_COMPANY_NAME}</h1>

            <button
                className="text-white focus:outline-none md:hidden"
                onClick={toggleMenu}
            >
                {/* Hamburger icon */}
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                </svg>
            </button>

            {/* Navigation links */}
            <nav className={`absolute md:static top-8 right-0 text-xl md:text-sm bg-blue-600 p-5 w-full md:flex md:w-auto md:p-0 md:bg-transparent ${isMenuOpen ? 'block' : 'hidden'}`} >
                <Link to="/" className="block md:inline-block mr-4">Home</Link>
                {isAuthenticated() ? (
                    <>
                        <Link to="/logout" className="block md:inline-block mr-4">Logout</Link>


                        {/* Check if user and user.email exist */}
                        <button className='text-blue-600 bg-white mr-5 px-4 py-1 rounded-lg'>
                            {user?.email || 'User'}
                        </button>

                        {hasRole(['super_admin', 'staff']) && (
                            <button className='text-white bg-pink-600 mr-5 px-4 py-1 rounded-lg'>
                                <Link to="/csv-uploader" className="block md:inline-block mr-4 text-xs">CSV Uploader
                                    {/* , only admin & staff can see and use */}
                                </Link>
                            </button>
                        )}

                        {hasRole(['panel_user']) && (
                            <button className='text-blue-600 bg-white mr-5 px-4 py-1 rounded-lg'>
                                Please whatsApp us at 011-12163808 to upgrade to VIP member for free
                            </button>
                        )}

                        {hasRole(['staff']) && (
                            <button className='text-blue-600 bg-white mr-5 px-4 rounded-lg'>
                                STAFF
                            </button>
                        )}

                        {hasRole(['dealer', 'member']) && (
                            <button className='text-blue-600 bg-white mr-5 px-4 rounded-lg'>
                                Member
                            </button>
                        )}

                        {hasRole(['fl']) && (
                            <button className='text-green-600 bg-white mr-5 px-4 rounded-lg'>
                                FL logged in
                            </button>
                        )}
                    </>
                ) : (
                    <>
                        <Link to="/login" className="block md:inline-block mr-4">Login</Link>
                        <Link to="/register" className="block md:inline-block mr-4">Register</Link>
                    </>
                )}
            </nav>
        </header>
    );
};

export default Header;
