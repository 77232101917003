// utils/auth.ts
import { api } from './api'; // Adjust the import path as necessary

// /src/utils/auth.ts

// Function to get the authentication token from localStorage or another source
export const getAuthToken = () => {
    return localStorage.getItem('token'); // Adjust this based on where you store your token
};

// Optionally, you can add a function to remove or set the token:
export const setAuthToken = (token: string) => {
    localStorage.setItem('token', token); api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

};

export const removeAuthToken = () => {
    localStorage.removeItem('token');
    delete api.defaults.headers.common['Authorization'];

};

// Function to check if the user has a specific permission
export const hasPermission = (permission: string): boolean => {
    const rolesPermissions = JSON.parse(localStorage.getItem('rolesPermissions') || 'null');
    return rolesPermissions && rolesPermissions.permissions.includes(permission);
};

// Function to check if the user has one of the specified roles
export const hasRole = (roles: string[]): boolean => {
    const rolesPermissions = JSON.parse(localStorage.getItem('rolesPermissions') || 'null');
    if (!rolesPermissions) return false;

    // Check if any of the specified roles are included in the user's roles
    return roles.some(role => rolesPermissions.roles.includes(role));
};

// Function to get all permissions
export const getPermissions = (): string[] => {
    const rolesPermissions = JSON.parse(localStorage.getItem('rolesPermissions') || 'null');
    return rolesPermissions ? rolesPermissions.permissions : [];
};

// Function to get all roles
export const getRoles = (): string[] => {
    const rolesPermissions = JSON.parse(localStorage.getItem('rolesPermissions') || 'null');
    return rolesPermissions ? rolesPermissions.roles : [];
};

// Function to check if the user is authenticated
export const isAuthenticated = (): boolean => {
    return localStorage.getItem('token') !== null; // Check if token exists
};