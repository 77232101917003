// src/pages/Home.tsx
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';

import Header from '../components/Header';
import CategoryList from '../components/CategoryList';
import BrandList from '../components/BrandList';

import ProductCard from '../components/ProductCard';
import { fetchCategories, fetchProducts, fetchBrands } from '../utils/api';
import { Category, Product, Brand } from '../utils/types'; // Import the types
import Pagination from '../components/Pagination'; // Import Pagination component
import { Tooltip as ReactTooltip } from "react-tooltip";
// import Spinner from '../components/Spinner'; // Import Spinner
import { FaBars } from 'react-icons/fa';
import { checkSession } from '../utils/api';
import { hasRole, isAuthenticated } from '../utils/auth';


const Home = () => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [sort, setSort] = useState('updated_at DESC'); // Default sort order
    const [perPage] = useState(60); // Products per page
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
    const [totalPages, setTotalPages] = useState(1);
    const [totalProducts, setTotalProducts] = useState(0);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            if (value.length >= 3) {
                setSearch(value);
                setCurrentPage(1);
            } else {
                setSearch(''); // Clear search if input is less than 3 characters
            }
        }, 300);
    }, []);

    const [brands, setBrands] = useState<Brand[]>([]);
    const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
    const [isBrandListVisible, setIsBrandListVisible] = useState(false);
    const [isCategoryListVisible, setIsCategoryListVisible] = useState(false);

    const brandMenuRef = useRef<HTMLDivElement>(null);
    const categoryMenuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');

        if (user && user.remember_token && user.session_id) {
            // console.log('App > useEffect > user.remember_token', user.remember_token);
            // console.log('App > useEffect > user.session_id', user.session_id);
            console.log(hasRole(['super_admin', 'staff']), ' cs time:', new Date().toLocaleString());

            const interval = setInterval(() => {
                checkSession();
                // console.log('checkSession interval');
            }, 5 * 60 * 1000); // Check every 5 minutes

            return () => clearInterval(interval); // Cleanup on unmount
        } else {
            console.log(hasRole(['super_admin', 'staff']), 'Current time:', new Date().toLocaleString());
        }

    }, []);

    useEffect(() => {
        const loadBrands = async () => {
            const brandData = await fetchBrands();
            setBrands(brandData);
        };

        const loadCategories = async () => {
            const categoryData = await fetchCategories();
            setCategories(categoryData);
        };

        loadBrands();
        loadCategories();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (isBrandListVisible && brandMenuRef.current && !brandMenuRef.current.contains(event.target as Node)) {
                setIsBrandListVisible(false);
            }
            if (isCategoryListVisible && categoryMenuRef.current && !categoryMenuRef.current.contains(event.target as Node)) {
                setIsCategoryListVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isBrandListVisible, isCategoryListVisible]);

    useEffect(() => {
        const loadInitialData = async () => {
            setLoading(true); // Set loading to true before fetching
            try {
                const [brandData, categoryData] = await Promise.all([fetchBrands(), fetchCategories()]);
                setBrands(brandData);
                setCategories(categoryData);
            } catch (err) {
                setError('Failed to load initial data.');
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        loadInitialData();
    }, []);

    // Fetch products data whenever pagination or filter criteria change
    useEffect(() => {
        const loadProducts = async () => {
            setLoading(true); // Set loading to true before fetching
            try {
                const { products, total_pages, total_products } = await fetchProducts(currentPage, search, selectedCategory?.id || null, selectedBrand?.id || null, perPage, sort);
                // console.log(total_products);
                setProducts(products);
                setTotalPages(total_pages);
                setTotalProducts(total_products);

            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        loadProducts();
    }, [currentPage, search, selectedCategory, selectedBrand, perPage, sort]);

    if (error) return <div>{error}</div>;


    const resetSelectedCategory = () => {
        setSelectedCategory(null);
    };
    const resetSelectedBrand = () => {
        setSelectedBrand(null);
    };



    return (
        <div>
            <Helmet>
                <title>Home | Computer IT distributor at Ipoh, Perak, Malaysia.</title>
                <meta name="description" content="Browse our collection of amazing products across various categories." />
                <meta name="keywords" content="computer distributor, ipoh, perak, malaysia, ecommerce, products, categories, shopping" />

                {/* Open Graph tags */}
                <meta property="og:title" content="Home | Computer IT distributor at Ipoh, Perak, Malaysia." />
                <meta property="og:description" content="Discover and shop a variety of products on our online store." />
                <meta property="og:image" content="https://price.ka.my/og-image.jpg" />
                <meta property="og:url" content="https://price.ka.my/" />
                <meta property="og:type" content="website" />

                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Home | Computer IT distributor at Ipoh, Perak, Malaysia." />
                <meta name="twitter:description" content="Explore our selection of products across different categories." />
                <meta name="twitter:image" content="https://price.ka.my/twitter-image.jpg" />
            </Helmet>

            <Header />
            {/* {loading && <Spinner />} */}
            {/* Search Input */}
            <div className="sticky top-10 z-20 bg-white flex flex-col md:flex-row mt-1 mb-4 p-0">

                <div className='text-md w-full md:w-4/6 mb-1 p-0'>
                    <form onSubmit={(e) => e.preventDefault()}> {/* Prevent form submission */}
                        <input
                            type="search"
                            name="search"
                            placeholder="Search products within the CURRENT Category only..."
                            onChange={handleSearchChange}
                            className="search-cancel:bg-red-500 search-cancel:text-white    bg-green-50  border border-1 border-green-600 m-0  px-2 py-1 w-full md:w-11/12"
                        />
                    </form>
                </div>

                <div className='text-xs md:text-sm w-full md:w-2/6  flex justify-end m-0 p-0'>
                    {/* Sort Options */}
                    <select name="sort" onChange={(e) => setSort(e.target.value)} value={sort} className='w-full h-8  border border-1 border-black rounded-lg m-0 p-0'>
                        <option value="updated_at ASC">Sort by Date (Oldest First)</option>
                        <option value="updated_at DESC">Sort by Date (Newest First)</option>
                        <option value="product_value ASC">Sort by Price (Low to High)</option>
                        <option value="product_value DESC">Sort by Price (High to Low)</option>
                    </select>
                </div>

            </div>


            {/* Display Spinner only during loading */}
            {/* {loading ? <Spinner /> : (
                <> */}
            <div className="flex">

                <button
                    className="z-30 fixed top-0 left-64 flex p-2 bg-white text-xs text-purple-600"
                    onClick={() => setIsBrandListVisible(!isBrandListVisible)}>
                    <FaBars size={16} /><span>Brand</span>
                </button>
                <div ref={brandMenuRef} className={`w-3/4 md:w-2/5 absolute top-10 left-0 z-30 bg-purple-50 brand-menu ${isBrandListVisible ? 'visible' : 'hidden'}`}>
                    {isBrandListVisible && <BrandList brands={brands} setSelectedBrand={setSelectedBrand} selectedBrand={selectedBrand} />}
                </div>

                <button
                    className="z-30 fixed top-0 left-36 flex  p-2 bg-white text-xs text-orange-600"
                    onClick={() => setIsCategoryListVisible(!isCategoryListVisible)}>
                    <FaBars size={16} /><span>Category</span>
                </button>
                <div ref={categoryMenuRef} className={`w-3/4 md:w-2/5 absolute top-10 left-0 z-30 bg-orange-50 category-menu ${isCategoryListVisible ? 'visible' : 'hidden'}`}>
                    {isCategoryListVisible && <CategoryList categories={categories} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />}
                </div>
            </div>

            <section className="products-section">

                <div className='sticky top-28 md:top-20 z-20  '>
                    <button style={{ fontSize: '0.8em' }} className='w-auto mx-2 mb-2 px-2 py-1 text-xs font-thin text-black  bg-orange-100 rounded-lg shadow-lg'>
                        Category: <span className='font-bold'>{selectedCategory ? selectedCategory.cat_name : 'All'}</span>
                        {selectedCategory && (
                            <span onClick={resetSelectedCategory} className="ml-2 text-red-500" data-tooltip-id={'resetCategoryTooltip'} data-tooltip-content={'Click X to reset the Category'}>
                                X
                            </span>
                        )}
                    </button>
                    <ReactTooltip
                        id={'resetCategoryTooltip'}
                        place="bottom"
                        style={{ backgroundColor: "yellow", color: "#000000" }}
                    />

                    <button style={{ fontSize: '0.8em' }} className='w-auto mx-2 mb-4 px-2 py-1 text-xs font-thin text-black bg-purple-100 rounded-lg shadow-lg'>
                        Brand: <span className='font-bold'>{selectedBrand ? selectedBrand.cat_name : 'All'}</span>

                        {selectedBrand && (
                            <span onClick={resetSelectedBrand} className="ml-2 text-red-500" data-tooltip-id={'resetBrandTooltip'} data-tooltip-content={'Click X to reset the Brand'}>
                                X
                            </span>
                        )}
                    </button>

                    <ReactTooltip
                        id={'resetBrandTooltip'}
                        place="bottom"
                        style={{ backgroundColor: "yellow", color: "#000000" }}
                    />
                </div>

                <div className='sticky md:fixed top-32 md:top-16 right-0 md:right-2  z-20'>
                    <button className='text-xs md:text-xs font-bold  w-42 mx-2 my-3 px-2 py-1  bg-green-100  rounded-lg shadow-lg '>
                        Products Loaded: {totalProducts}
                    </button>
                </div>

                <div className="flex flex-wrap justify-start m-0">
                    {products.length > 0 ? (
                        products.map((product) => (
                            <ProductCard key={product.id} product={product} />
                        ))
                    ) : (
                        (selectedCategory || selectedBrand || search) ? (
                            <div className='w-full flex flex-row justify-center items-center mt-10'>
                                <p className='text-pink-600'>No products available for your Search, Category or Brand selections . . .</p>
                            </div>
                        ) : (
                            <div className="flex items-center space-x-1 text-pink-600">
                                <span>Loading</span>
                                <span className="animate-dot1">.</span>
                                <span className="animate-dot2">.</span>
                                <span className="animate-dot3">.</span>
                            </div>
                        )
                    )}
                </div>

                {totalPages > 1 && <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />}
            </section>
            {/* </>
            )} */}
        </div>
    );
};

export default Home;
