import React, { useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import PasswordReset from './pages/PasswordReset';
import Logout from './pages/Logout';
import Register from './pages/Register';
import Header from './components/Header';
import Footer from './components/Footer';
import CsvUploader from './components/CSVUploader';
// import MeteorPreview from './pages/MeteorPreview';
import ProductPage from './pages/ProductPage'; // duplicated from ProductModal
import { AuthProvider } from './context/AuthContext'; // Import AuthProvider
import { HelmetProvider } from 'react-helmet-async';

const App = () => {



  return (
    <HelmetProvider>

      <AuthProvider>

        {/* <Router> */}
        <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true, }}>
          <div className="flex flex-col min-h-screen ">
            <HeaderWrapper /> {/* Use HeaderWrapper to conditionally render Header */}

            <main className="flex-grow">
              <Routes>
                <Route path="/" element={<Home />} />

                {/* <Route path="/product/:id" element={<ProductPage />} /> */}
                {/* New route for product details */}
                <Route path="/product/:productId/:productName?" element={<ProductPage />} />

                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<PasswordReset />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/csv-uploader" element={<CsvUploader />} />

                {/* <Route path="/meteor" element={<MeteorPreview />} /> */}

              </Routes>
            </main>
            <Footer />
          </div>

        </BrowserRouter>

        {/* </Router > */}

      </AuthProvider >

    </HelmetProvider>

  );
};

const HeaderWrapper = () => {
  const location = useLocation();

  // Hide the header if the path is the root
  const shouldHideHeader = location.pathname === '/';

  return shouldHideHeader ? null : <Header />;
};

export default App;
